import {unescapeAttr} from '../src/helpers'

class HeadMeta {
  static tagname = 'head-meta'
  init(event){
    this.root = event.currentTarget;
  }
  onconnected(e){
    if(this.git('disabled') != 'true'){
      document.title = this.git('title')
      document.querySelector('meta[name="description"]').content = this.git('description')
      document.querySelector('meta[name="image"]').content = this.git('image')
      document.querySelector('meta[name="keywords"]').content = this.git('keywords')
    }
  }
  git = (name) => {
    return unescapeAttr(this.root.getAttribute(name) || '')
  }
}

export default HeadMeta
