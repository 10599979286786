import {checkUrl, hydrateAttrs} from '../src/helpers'
import Component from '../src/component'
import fetch from 'isomorphic-fetch'

class IncludeFile extends Component{
  props = {}
  state = {content: ''}
  static tagname = 'include-file'
  connect = async () => {
    await this.load()
  }
  load = async () => {
    const {file, title, nohead} = this.props
    console.log('INCLUDING', file);
    const extension = file.split('.').pop()
    const url = checkUrl(file)
    const res = await fetch(url).then(r => r.text())
    const content = nohead == 'true'? this.commentMeta(res): res
    if(extension === 'html'){
      this.setState({content})
    }
    else if(['txt', 'ascii'].includes(extension)){
      this.setState({content: `<pre>\n${content}\n</pre>`})
    }
    else {
      this.setState({content: `Unsuported file type ${extension}`})
    }
  }
  onAttributechanged = async () => {
    await this.load()
  }
  render = ({className}, {content}) => {
    this.root.innerHTML = `
<div class="${className || 'include-file-contents'}">
  ${content}
</div>`
  }
  commentMeta(txt){
    return txt.replace(/< *head-meta/ , '<head-meta disabled="true" ')
  }
}

export default IncludeFile
