import {render, html} from 'lit-html'
class ComponentError extends Error {}
function mapComponents(components){
  var map = {}
  for(var k in components){
    if(components[k].tagname){
      map[tagname] = components[k]
    }
    else {
      throw new ComponentError(`${k} component has no tagname property`)
    }
  }
}
function defineComponents(components, define){
  for(var k in components){
    const tagname = components[k].tagname
    if(tagname){
      define(tagname, components[k])
    }
    else {
      throw new ComponentError(`${k} component has no tagname property`)
    }
  }
}
function isPromise(obj) {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
}
function promify(obj){
  return isPromise(obj)? obj: Promise.resolve(obj)
}
function functionify(obj){
  return typeof obj === 'function'? obj: () => {return obj}
}
function routeFunction(routeObj, route, rootElement){
  const funct = functionify(routeObj.content || html`<include-file file="${routeObj.path}"></include-file>`)
  const actualObject = typeof obj === 'function'? {}: routeObj
  if(actualObject.content) delete routeObj.content
  return function(params){
    const props = Object.assign(params, actualObject)
    const content = funct(props)
    if(typeof content == 'string') {
      rootElement.innerHTML = content
    }
    else {
      render(content, rootElement)
    }
  }
}
function addAllRoutes(routes, navaid, rootElement, base){
  const baseRegex = new RegExp(`^${base}`)
  for(var route in routes){
    const funct = routeFunction(routes[route], route, rootElement)
    navaid.on(route.replace(baseRegex, ''), params => funct(params))
  }
}
function isJson(str){
  return /(^\[[\s\S]*\]$)|(^\{[\s\S]*\}$)/.test(str)
}
function parseJsonOrFalse(str){
  if(typeof str == 'string'){
    if(isJson(str)){
      try { return JSON.parse(str)} catch (ex) {return false;}
    }
  }
  return false
}
function transformString(str){
  var obj, stripped = str.trim()
  if(/((^\d+$)|(^\.\d+$)|(^\d+\.\d+$))/.test(stripped)) return Number(stripped)
  if(/^(true|false)$/.test(str)) return str == 'true'? true: false
  if(obj = parseJsonOrFalse(stripped)) return obj
  return str
}
function escapeAttr(unsafe) {
  return unsafe.replace(/'/g, "&#039;").replace(/"/, "'")
}
function unescapeAttr(str){
  return str.replace(/'/g, '"').replace('&#039;', "'")
}
function hydrateAttrs(attrs){
  var newAttrs = {}
  for (var i = 0; i < attrs.length; i++){
    const value = attrs[i].value
    const name = attrs[i].name
    if(isJson(value)){
      newAttrs[name] = JSON.parse(unescapeAttr(value))
    }
    else {
      newAttrs[name] = transformString(value)
    }
  }
  return newAttrs
}
function getProto(component){
  return typeof component === 'function' ? component.prototype : component;
}
function join(){
  var arr = Array.from(arguments)
  var str = arr[0]
  var s = '';
  arr.shift()
  arr.forEach(a => {
      s = a.replace(/^\/+/mg, '').replace(/:\/+/, ':/')
      str = str + '/' + s
  })
  return str
}
function  checkUrl(path){
  if(path.startsWith('http')){
    return path
  }
  return join(window.location.origin, path)
}
function webStorageExists() {
 try {
    localStorage.setItem('_item', 'test');
    localStorage.removeItem('_item');
    return true;
  } catch(e) {
      return false;
  }
}
function getLocalItem(key){
  if(!webStorageExists()) return
  return localStorage.getItem(key)
}
function setLocalItem(key, value){
  if(!webStorageExists()) return
  localStorage.setItem(key, value)
}
function removeLocalItem(key){
  if(!webStorageExists()) return
  localStorage.removeItem(key)
}
function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}
function titleCase(str) {
  return str.toLowerCase().replace(/(-)|(_)/g, ' ').replace(/(.*) the$/, 'the $1').split(' ').map(function(word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}
function propsMixin(props, parent){
  var ref = parent || this
  ref.props = ref.props || {}
  const attrs = hydrateAttrs(ref.element.attributes)
  Object.assign(ref.props, props || {}, attrs)
}
const storage = {
  getItem: getLocalItem,
  setItem: setLocalItem,
  removeItem: removeLocalItem,
  storageExists: webStorageExists
}

export {
  mapComponents,
  defineComponents,
  isPromise,
  promify,
  functionify,
  titleCase,
  addAllRoutes,
  hydrateAttrs,
  getProto,
  unescapeAttr,
  escapeAttr,
  checkUrl,
  join,
  storage,
  getUrlParameter,
  propsMixin
}
