import Navaid from 'navaid'
import {render, html} from 'lit-html'
import {addAllRoutes} from '../src/helpers'

function notFound(routes, root){
  var content = html`<h1>Page Not Found 404</h1>`
  if(routes['/notfound']){
    var {path, content} = routes['/notfound']
    content = path? html`<include-file file="${path}"></include-file>`: content || routes['/notfound']
  }
  else if(routes['/404']){
    var {path, content} = routes['/404']
    content = path? html`<include-file file="${path}"></include-file>`: content || routes['/404']
  }
  return function(params) {
    render(content, root)
  }
}

class Router {
  setRoot = (root) => {
    this.root = root
  }
  setRoutes = ({routes, base='/', url='/'}) => {
    this.router = new Navaid(base, notFound(routes, this.root))
    addAllRoutes(routes, this.router, this.root, base)
    this.router.listen();
    if(window.location.pathname != url){
      this.navigate(url)
    }
  }
  on = (path, route) => {
    this.router.on(path, route)
  }
  navigate = (url, state={}) => {
    console.log('navigating to route', url);
    if(history) {
      history.pushState(state, 'navigate', url)
    }
    else{
      this.router.run(url)
    }
  }
}

export {Router}
