import DragonBinder from 'dragonbinder'
import Api from './api'
import {Router} from './router'
import {titleCase, storage} from './helpers'
const api = new Api('/')
const su = ['/login', '/logout']
const router = new Router()
const studentRouter = new Router()

// see https://www.npmjs.com/package/dragonbinder
let store = new DragonBinder({
  state: {
    menuitems: [],
    routes: {},
    studentMenuItems: [],
    studentRoutes: {},
    sections: [],
    sectionOrder: 'desc',
    user: undefined,
    appRoot: undefined,
    config: {}
  },
  getters: {
    user(state){
      return state.user
    }
  },
  mutations: {
    setConfig(state, config){
      Object.assign(state, config)
    },
    setMenuItems(state, menuitems) {
      state.menuitems = menuitems
    },
    setRoutes(state, routes){
      router.setRoutes({routes, url: window.location.pathname})
      router.on('/logout', function(){store.dispatch('logout')})
      state.routes = routes
    },
    setStudentMenuItems(state, menuitems) {
      state.studentMenuItems = menuitems
    },
    setStudentRoutes(state, routes){
      const {username, hash} = state.user
      router.setRoutes({routes, base: `/students/${username}`, url: window.location.pathname})
      state.studentRoutes = routes
    },
    setSections(state, sections){
      state.sections = sections
    },
    setSectionOrder(state, order){
      state.sectionOrder = order
    },
    setUser(state, user){
      state.user = user
    },
    setAppRoot(state, root){
      router.setRoot(root)
      state.appRoot = root
    }
  },
  actions: {
    async fetchMenuItems(store, {menupath}){
      var menuitems = await api.get(menupath)
      store.commit('setMenuItems', menuitems)
    },
    async fetchStudentMenuItems(store, {menupath}){
      var menuitems = await api.get(menupath)
      store.commit('setStudentMenuItems', menuitems)
    },
    async fetchRoutes(store, {routespath}){
      const routes = await api.get(routespath)
      store.commit('setRoutes', routes)
    },
    async fetchStudentRoutes(store, {routespath}){
      const routes = await api.get(routespath)
      store.commit('setStudentRoutes', routes)
    },
    async addLogout(store){
      var menuitems = store.state.menuitems.filter(i => !su.includes(i.url))
      // menuitems.push({url: '/logout', title: 'Logout', menu: 10})
      store.commit('setMenuItems', menuitems)
    },
    async addLogin(store){
      var menuitems = store.state.menuitems.filter(i => !su.includes(i.url))
      menuitems.push({url: '/login', title: 'Login', menu: 10})
      store.commit('setMenuItems', menuitems)
    },
    async login(store, {username, hash}){
      const homeurl = `/_content/students/${hash}`
      var info = await api.get(`${homeurl}/info.json`)
      store.commit('setUser', Object.assign({username, hash, name: titleCase(username)}, info || {}))
      storage.setItem('username', username)
      storage.setItem('hash', hash)
      await store.dispatch('fetchStudentRoutes', {routespath: `/${homeurl}/routes.json`})
      await store.dispatch('fetchStudentMenuItems', {menupath: `/${homeurl}/menu.json`})
      await store.dispatch('addLogout')
    },
    async defaultLogin(store){
      var {menuUrl, routesUrl} = store.state
      //store.commit('setUser', {username:'default', hash:'', name: titleCase('Anon')})
      await store.dispatch('fetchRoutes', {routespath: `/${routesUrl}`})
      await store.dispatch('fetchMenuItems', {menupath: `/${menuUrl}`})
    },
    async logout(store){
        storage.removeItem('username')
        storage.removeItem('hash')
        store.commit('setUser', {})
        store.commit('setStudentMenuItems', [])
        store.commit('setStudentRoutes', {routes:{}})
        await store.dispatch('addLogin')
        router.navigate('/')
    },
    async fetchSections(store, query){
      var {studentRoutes, user} = store.state
      var regex = new RegExp(`/students/${user.username}/repertoire/.+`)
      var sections = []
      Object.keys(studentRoutes)
        .forEach( k => {
          if(regex.test(k)) sections.push(Object.assign({}, {route: k}, studentRoutes[k]))
      })
      store.commit('setSections', sections)
    },
    async toggleSectionOrder(store, order){
      var sections = Object.assign([], store.state.sections)
      var newOrder = order? order: store.state.sectionOrder === 'asc'? 'desc': 'asc'
      sections.sort((a,b) => newOrder === 'asc'? a.mtime - b.mtime: b.mtime - a.mtime)
      store.commit('setSectionOrder', newOrder)
      store.commit('setSections', sections)
    },
    async navigate(store, url){
      router.navigate(url)
    }
  }
});

export default store
