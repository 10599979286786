import Component from '../src/component'
import {render, html} from 'lit-html'
const css = String.raw

class LogOut extends Component{
  static tagname = 'log-out'
  state = {
    errors: {},
    username: '',
    hash: ''
  }
  connect = async (store) => {
    this.getUser = () => {return store.getters.user}
    this.logout = async () => {await store.dispatch('logout')}
    let loglistener = (store, prop, newVal, oldVal) => {if(prop == 'user') this.render()}
    store.on('set', loglistener);
  }
  render(){
    var user = this.getUser()
    var result = user && Object.keys(user).length > 0? html`
      <div class="logout-container">
        <span></span>
        <span @click=${this.logout}>logout</span>
      </div>
    `: null;
    render(result , this.root)
  }
  static style = css`
    .logout-container {
      display: flex;
      position: absolute;
      width: 100%;
      z-index: 2;
    }
    .logout-container span:nth-child(1){
      flex: 10;
    }
    .logout-container span:nth-child(2){
      text-align: right;
      text-decoration: underline;
      flex: 0;
      padding: 10px;
    }
    .logout-container span:nth-child(2):hover {
      cursor: pointer;
    }
  `
}

export default LogOut
