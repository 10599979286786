import qparam from 'jquery-param'
import {checkUrl, join} from './helpers'
import fetch from 'isomorphic-fetch'
class ApiError extends Error {}

class Api {
  constructor(base){
    this.base = checkUrl(base).replace(/\/$/, '')
    this.get = this.get.bind(this)
  }
  async get(table, params){
    const paramstr = params? '?' + qparam(params): ''
    const url = join(this.base, `${table}${paramstr}`)
    console.log('API GET', url);
    return await fetch(url).then(r =>  {
      if(!r.ok) throw new ApiError(`failed to fetch "${url}". status: ${r.status}`)
      return r.json()
    })
  }
  async text(table, params){
    const paramstr = params? '?' + qparam(params): ''
    const url = join(this.base, `${table}${paramstr}`)
    console.log('API GET TEXT', url);
    return await fetch(url).then(r =>  {
      if(!r.ok) throw new ApiError(`failed to fetch "${url}". status: ${r.status}`)
      return r.text()
    })
  }
}

export default Api
