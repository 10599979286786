import Component from '../src/component'
import {render, html} from 'lit-html'
const css = String.raw

class MainMenu extends Component{
  static tagname = 'main-menu'
  state = {
    mainmenu:[],
    studentmenu: [],
    url: window.location.pathname
  }
  async connect(store){
      const mainmenu = store.state.menuitems
      const studentmenu = store.state.studentMenuItems
      this.setState({mainmenu, studentmenu})
      this.removeListener = store.on('set', (store, prop, newVal, oldVal) => {
        if(prop === 'menuitems'){
           this.setState({mainmenu: newVal}, true)
        }
        else if(prop === 'studentMenuItems'){
          this.setState({studentmenu: newVal}, true)
        }
      })
      window.addEventListener('popstate', (event) => {
        this.setState({url: window.location.pathname})
      });
      window.addEventListener('pushstate', (event) => {
        this.setState({url: window.location.pathname})
      });
  }
  onDisConnected(){
    this.removeListener()
  }
  removeResponsive = () => {
    this.root.querySelector('.main-nav').classList.remove('responsive-nav')
  }
  render = (props, {mainmenu, studentmenu, url}) => {
    render(html`
      <div class="main-nav">
        <div class="dummy-nav-link">&nbsp;</div>
        <div class="main-menu">
        ${mainmenu.length > 0? mainmenu.map(i => html`<div class="nav-link-cell ${i.title == 'Home'? ' menu-home-cell': ''}"><a
            class="nav-link shake${url == i.url? ' active-link': ''}"
            href="${i.url}"
            @click=${this.removeResponsive}>${i.title == 'Home'? html`<i class="icon-home"></i>`: i.title}
          </a></div>`): null}
        </div>
        <div class="student-menu">
        ${studentmenu.length > 0? studentmenu.map(i => html`<div class="student-link-cell"><a class="nav-link${url == i.url? ' active-link': ''}" href="${i.url}" @click=${this.removeResponsive}>${i.title}</a></div>`): null}
        </div>
        <div class="menu-icon-div">
          <span
            @click=${() => {this.root.querySelector('.main-nav').classList.toggle('responsive-nav')}}
            class="menu-icon pointer">
            |||
          </span>
        </div>
      </div>
      <log-out></log-out>
    `, this.root)
  }
  static style = css`
  .main-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: var(--menu-color);
    color: var(--color-white);
    height: var(--header-height);
    box-shadow: 3px 3px 3px #80795a;
    position: relative;
    z-index: 5;
    /* opacity: 100%; */
  }
  .main-nav a {
    display: inline-block;
  }
  .nav-link {
    color: var(--color-white);
    padding: 0px 14px;
    line-height: var(--header-height);
    flex-wrap: nowrap;
    white-space: nowrap;
    height: 100%;
    display: inline-block;
    z-index: 10;
    text-align: center;
  }
  .active-link {
    color: var(--color-gold)!important;
  }
  .nav-link:visited {
    color: inherit;
  }
  .nav-link:hover {

  }
  .nav-link-cell {
    display: inlin-block;
    text-align: left;
  }
  .nav-link-cell:hover {
    cursor: pointer;
    background: var(--menu-color-lighter);
  }
  .menu-icon {
    display: inline;
    float: right;
    line-height: var(--header-height);
    padding: 0px 14px;
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari 3-8 */
    transform: rotate(90deg);
  }
  .student-menu {
    flex: 1
    position: relative;
  }
  .main-menu {
    position: relative;
    flex: 2
  }
  .menu-icon-div {
    flex: 0;
  }
  .menu-home-cell{
    display: inline-block;
  }
  .main-menu .nav-link-cell:not(.menu-home-cell){
    display: none;
  }
  /* .menu-home-link {
    display: inline;
    border-top: none;
  } */
  .student-menu a{
    display: none;
  }
  .responsive-nav {
    flex-direction: column;
    height: auto;
  }
  .responsive-nav  > div {
    width: 100%;
    display: block;
  }
  .responsive-nav .nav-link{
    display: inline-block !important;
  }
  .responsive-nav .student-menu {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .responsive-nav .main-menu {
    display: flex;
    flex-direction: column;
  }
  .responsive-nav .menu-icon-div {
    position: fixed;
  }
  .responsive-nav .nav-link-cell {
    border-top: 1px solid var(--menu-color-lighter);
    display: block !important;
    width: 100%;
    height: var(--header-height);
    text-align: center;
  }
  .responsive-nav .student-link-cell {
    border-top: 1px solid var(--menu-color-lighter);
    display: block !important;
    width: 100%;
    height: var(--header-height);
    text-align: center;
  }
  .dummy-nav-link {
    display: none;
  }
  .responsive-nav .dummy-nav-link{
    display: block;
    height: var(--header-height);
  }
  @media (min-width: 700px) {
    .menu-icon {
      display: none;
    }
    .menu-icon-div {
      display: inline-block;
    }
    .nav-link {
      display: inline-block;
    }
    .nav-link-cell {
      display: inline-block !important;
      width: auto;
    }
    .student-link-cell {
      display: inline-block !important;
    }
    .student-menu {
        text-align: right;
    }
    .student-menu a{
      display: inline;
    }
    .nav-link {
      padding: 0px 14px;
      display: auto;
      border-top: none;
    }
  }

  `
}
export default MainMenu
