import Component from '../src/component'
import {render, html} from 'lit-html'
const css = String.raw

class SectionNav extends Component{
  static tagname = 'section-nav'
  get observedattributes(){return ['sectionid', 'studentid']}
  state = {prev: '', next: ''}
  async connect(store){
    var {sections} = store.state
    if(sections.length == 0){
      store.dispatch('fetchSections')
    }
    const updateLinks = (store, prop, newVal, oldVal) => {if(prop == 'sections') this.setPrevNext(newVal);}
    store.on('set', updateLinks)
    if(sections.length > 0){
      this.setPrevNext(sections)
    }
  }
  setPrevNext(sections){
    var i, index = 0
    for (i = 0; i < sections.length; i++) {
      if(sections[i].route === window.location.pathname){
        index = i
        break;
      }
    }
    const pi = ((index - 1) + sections.length) % sections.length
    const ni = ((index + 1) + sections.length) % sections.length
    const prev = sections[pi].route
    const next = sections[ni].route
    this.setState({prev, next})
  }
  render = (props, {prev, next}) => {
    render(html`
<div class="section-nav">
  <div>
    <span class="sn-left"><a href="${prev}"><i class="icon-left-big"></i></a></span>
    <span class="sn-right"><a href="${next}"><i class="icon-right-big"></i></a></span>
  </div>
</div>
    `, this.root)
  }
  static style = css`
.section-nav {
  position: relative;
  width: 100%;
}
.section-nav > div{
  width: 100%;
  position: relative;
  top: 200px;
}
.section-nav a:visited {
  color: inherit;
}
.sn-left, .sn-right {
  position: relative;
  font-size: 200%;
  opacity: 0.1;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  z-index: 10;
}
.sn-left:hover, .sn-right:hover {
  opacity: 0.5;
  background-color: var(--color-pale-grey);
}
.sn-left {
  float: left;
}
.sn-right {
  float: right;
}
  `
}

export default SectionNav
