import Component from '../src/component'
import {render, html} from 'lit-html'
import Api from '../src/api'
const api = new Api('/')
const RESOURCE_DIR = '/public/resources'
const css = String.raw

class ResourceComp extends Component{
  static tagname = 'resource-comp'
  get observedattributes(){return ['slug', 'title', 'timesig', 'composer', 'showtitle', 'addmeta', 'mode', 'tempo']}
  state = {png: [], audio: [], midi: [], ascii: [], folder: 'guitar'}
  async connect(store){
    var count
    var {slug, mode} = this.props
    var smap = await api.get(`${RESOURCE_DIR}/smaps/${slug}.json`)
    var {png, audio, midi, ascii, title, timesig, composer, tempo} = smap
    if(mode != 'single'){
      count =  await api.text(`${RESOURCE_DIR}/split-png/${slug}/count.txt`).then(t => parseInt(t.trim())).catch(() => 0);
    }
    this.setState({png, audio, midi, ascii, title, timesig, composer, slug, count, tempo})
  }
  wholePngRender(png){
    return png.length > 0?
    html`<div class="png-resource">
      ${png.map(r => html`<img src="${RESOURCE_DIR}/png/${r}"/>`)}
    </div>` : null
  }
  systemPngRender({slug, count}){
    var range = [...Array(count).keys()]
    // console.log(range);
    return range.map( i => {
      return html`
        <div class="png-system">
          <img src="${RESOURCE_DIR}/split-png/${slug}/${slug}-${i + 1}.png"/>
        </div>
      `
    })
  }
  render = ({showtitle, addmeta}, {png, audio, midi, ascii, title, timesig, slug, mode, count, systemgap, tempo}) => {
    var slugSpan = window.location.pathname.startsWith('/admin')? html`<span class="slug-span">${slug}</span>`: ''
    render( html`
${addmeta? html`<head-meta title="${title}"></head-meta>`: ''}
  ${showtitle? html`<h1>${title}${slugSpan}</h1>`: ''}
  <div class="metro-container">
    <metro-gnome
      lowfile="/public/audio/kick.mp3"
      highfile="/public/audio/side-stick.mp3"
      meter="${timesig}"
      tempo="${tempo || '55'}"
    ></metro-gnome>
  </div>
  <div class="fadein">
    ${audio.length > 0?
      html`<div class="audio-resource">
        ${audio.map(r => html`<audio src="${RESOURCE_DIR}/audio/${r}" controls></audio>`)}
      </div>`: null
    }
    ${mode == 'single'? this.wholePngRender(png): this.systemPngRender({slug, count})}
    ${ascii.length > 0?
      html`<div class="ascii-resource">
        ${ascii.map(r => html`<pre><include-file file="${RESOURCE_DIR}/ascii/${r}" nohead="true"></include-file></pre>`)}
      </div>` : null
    }
  </div>
    `, this.root)
  }
  static style = css`
  .png-system{
    max-width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  .ascii-resource {
    width: 100%;
    overflow-x: scroll;
  }
  .png-system img {
    max-width: 100%;
    padding-top: 3%;
  }
  .slug-span {
    color: var(--color-pale-grey);
    font-size: 20px;
    margin-left: 1em;
  }
  .metro-container {
    margin-top: 1em;
  }
  @media (min-width: 700px) {
    .png-system {
      min-height: 100px;  
    }
    .png-system img {
      max-width: 100%;
      padding-top: 3%;
    }
  }
`
}

export default ResourceComp
