import {render, html} from 'lit-html';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {storage, propsMixin} from '../src/helpers'
import Api from '../src/api'
const css = String .raw


const INTERMEDIATE_CHORDS = {
  "D": "x;x;o;2-1;3-3;2-2",
  "Dm": "x;x;o;2-2;3-3;1-1",
  "Dm7": "x;x;o;2-2;1-1;1-1",
  "D7": "x;x;o;2-2;1-1;2-3",
  "D5": "x;x;o;2-1;x;x",
  "Dmaj7": "x;x;o;2-1;2-1;2-1",
  "Dsus4": "x;x;o;2-1;3-3;3-4",
  "Db": "x;1-1;3-2;3-3;3-4;1-1#4",
  "Db7": "x;1;3-3;o;3-4;1-1#4",
  "Dbm": "x;1-1;3-3;3-4;2-2;1-1#4",
  "Dbm7": "x;1-1;3-3;o;2-2;1-1#4",
  "Db5": "x;1-1;3-3;x;x;x#2",
  "Dbmaj7": "x;1;3-3;2-2;3-4;1-1#4",
  "Dbsus4": "x;1-1;3-2;3-3;4-4;1-1#4",
  "C#": "x;1-1;3-2;3-3;3-4;1-1#4",
  "C#7": "x;1;3-3;o;3-4;1-1#4",
  "C#m": "x;1-1;3-3;3-4;2-2;1-1#4",
  "C#m7": "x;1-1;3-3;o;2-2;1-1#4",
  "C#5": "x;1-1;3-3;x;x;x#2",
  "C#maj7": "x;1;3-3;2-2;3-4;1-1#4",
  "C#sus4": "x;1-1;3-2;3-3;4-4;1-1#4",
  "G": "3-2;2-1;o;o;o;3-3",
  "Gm": "1-1;3-3;3-4;1-1;1-1;1-1#3",
  "Gm7": "1-1;3-3;1-1;1-1;1-1;1-1#3",
  "G7": "3-3;2-2;o;o;o;1-1",
  "G5": "1-1;3-3;x;x;x;x#3",
  "Gmaj7": "3-1;x;3-3;3-4;2-2;x#3",
  "Gsus4": "3-3;2-3;o;o;1-1;3-4",
  "C": "x;3-3;2-2;o;1-1;o",
  "Cm": "x;1-1;3-3;3-4;2-2;1-1#3",
  "Cm7": "x;1-1;3-3;1-1;2-2;1-1#3",
  "C7": "x;3-3;2-2;3-4;1-1;o",
  "C5": "x;1-1;3-3;x;x;x#3",
  "Cmaj7": "x;3-3;2-2;o;o;o",
  "Csus4": "x;3-3;3-4;o;1-1;x",
  "Cadd9": "x;3-2;2-1;o;3-3;3-4",
  "A": "x;o;2-1;2-2;2-3;o",
  "Am": "x;o;2-2;2-3;1-1;o",
  "Am7": "x;o;2-2;1-1;1-1;o",
  "A7": "x;o;2-1;o;2-2;o",
  "A5": "x;o;2-1;x;x;x",
  "Amaj7": "x;o;2-2;1-1;2-3;o",
  "Asus4": "x;o;2-1;2-2;3-3;o",
  "A7sus4": "x;o;2-1;2-2;3-3;3-4",
  "Am7b5": "2-2;x;2-3;2-4;1-1;x#4",
  "F": "x;x;3-3;2-2;1-1;1-1",
  "Fm": "x;x;3-3;1-1;1-1;1-1",
  "Fm7": "x;x;1-1;1-1;1-1;1-1",
  "F7": "1-1;3-3;1-1;2-2;1-1;1-1",
  "F5": "1-1;3-3;x;x;x;x",
  "Fmaj7": "x;x;3-3;2-2;1-1;o",
  "Fsus4": "x;x;3-3;3-4;1-1;1-1",
  "F#": "1-1;3-3;3-4;2-2;1-1;1-1#2",
  "F#7": "1-1;3-3;1-1;2-2;1-1;1-1#2",
  "F#m": "1-1;3-3;3-4;1-1;1-1;1-1#2",
  "F#m7": "1-1;3-3;1-1;1-1;1-1;1-1#2",
  "F#5": "1-1;3-3;x;x;x;x#2",
  "F#maj7": "3-1;x;3-3;3-4;2-2;x#2",
  "F#sus4": "1-1;3-2;3-3;3-4;1-1;1-1#2",
  "F#m7b5": "2-2;x;2-3;2-4;1-1;x",
  "Ab": "1-1;3-3;3-4;2-2;1-1;1-1#4",
  "Ab7": "1-1;3-3;1-1;2-2;1-1;1-1#4",
  "Abm": "1-1;3-3;3-4;1-1;1-1;1-1#4",
  "Abm7": "1-1;3-3;1-1;1-1;1-1;1-1#4",
  "Ab5": "1-1;3-3;x;x;x;x#4",
  "Abmaj7": "3-1;x;3-3;3-4;2-2;x#4",
  "Absus4": "1-1;3-2;3-3;3-4;1-1;1-1#4",
  "G#": "1-1;3-3;3-4;2-2;1-1;1-1#4",
  "G#7": "1-1;3-3;1-1;2-2;1-1;1-1#4",
  "G#m": "1-1;3-3;3-4;1-1;1-1;1-1#4",
  "G#m7": "1-1;3-3;1-1;1-1;1-1;1-1#4",
  "G#5": "1-1;3-3;x;x;x;x#4",
  "G#maj7": "3-1;x;3-3;3-4;2-2;x#4",
  "G#sus4": "1-1;3-2;3-3;3-4;1-1;1-1#4",
  "E": "o;2-2;2-3;1-1;o;o",
  "Em": "o;2-1;2-2;o;o;o",
  "Em7": "o;2-1;o;o;o;o",
  "E7": "o;2-2;o;1-1;o;o",
  "E5": "o;2-1;x;x;x;x",
  "Emaj7": "x;x;1-1;3-3;3-3;3-3#2",
  "Esus4": "o;2-2;2-3;3-4;o;o",
  "E7#9": "x;2-2;1-1;3-2;3-4;x#6",
  "Em7b5": "x;x;2-1;3-3;3-3;3-3",
  "Eb": "x;4-4;3-3;1-1;2-2;x#3",
  "Ebm": "x;1-1;3-3;3-4;2-2;1-1#6",
  "Ebm7": "x;1-1;3-3;1-1;2-2;1-1#6",
  "Eb7": "x;3-3;2-2;3-4;1-1;x#4",
  "Eb5": "x;1-1;3-3;x;x;x#6",
  "Ebmaj7": "x;4-4;3-3;1-1;1-1;1-1#3",
  "Ebsus": "x;3-2;3-3;3-4;1-1;x#4",
  "D#": "x;4-4;3-3;1-1;2-2;x#3",
  "D#m": "x;1-1;3-3;3-4;2-2;1-1#6",
  "D#m7": "x;1-1;3-3;1-1;2-2;1-1#6",
  "D#7": "x;3-3;2-2;3-4;1-1;x#4",
  "D#5": "x;1-1;3-3;x;x;x#6",
  "D#maj7": "x;4-4;3-3;1-1;1-1;1-1#3",
  "D#sus": "x;3-2;3-3;3-4;1-1;x#4",
  "B": "x;1-1;3-2;3-3;3-4;1-1#2",
  "B7": "x;2-2;1-1;2-3;o;2-4",
  "Bm": "x;1-1;3-3;3-4;2-2;1-1#2",
  "Bm7": "x;1-1;3-3;o;2-2;1-1#2",
  "B5": "x;1-1;3-3;x;x;x#2",
  "Bmaj7": "x;1;3-3;2-2;3-4;1-1#2",
  "Bsus4": "x;2-2;2-3;2-4;o;x#2",
  "Bm7b5": "x;2-1;3-3;2-2;2-4;x",
  "Bb": "x;1-1;3-2;3-3;3-4;1-1",
  "Bb7": "x;2-2;1-1;2-3;o;2-4",
  "Bbm": "x;1-1;3-3;3-4;2-2;1-1",
  "Bbm7": "x;1-1;3-3;o;2-2;1-1",
  "Bb5": "x;1-1;3-3;x;x;x",
  "Bbmaj7": "x;1;3-3;2-2;3-4;1-1",
  "Bbsus4": "x;1-1;3-2;3-3;4-4;1-1",
  "A#": "x;1-1;3-2;3-3;3-4;1-1",
  "A#7": "x;2-2;1-1;2-3;o;2-4",
  "A#m": "x;1-1;3-3;3-4;2-2;1-1",
  "A#m7": "x;1-1;3-3;o;2-2;1-1",
  "A#5": "x;1-1;3-3;x;x;x",
  "A#maj7": "x;1;3-3;2-2;3-4;1-1",
  "A#sus4": "x;1;3-2;3-3;4-4;1-1",
  "Adim": "x;o;1-1;o;1-2;x",
  "A#dim": "x;1-1;2-3;o;2-4;x",
  "Bbdim": "x;1-1;2-3;o;2-4;x",
  "Bdim": "x;2-2;3-3;1-1;3-4;x#2",
  "Cdim": "x;2-2;3-3;1-1;3-4;x#3",
  "C#dim": "x;2-2;3-3;1-1;3-4;x#4",
  "Dbdim": "x;2-2;3-3;1-1;3-4;x#4",
  "Ddim": "x;x;o;1-1;o;1-2",
  "D#dim": "x;x;1-1;2-3;1-2;2-4",
  "Ebdim": "x;x;1-1;2-3;1-2;2-4",
  "Ebdim": "x;x;2-1;3-3;2-2;3-4",
  "Fdim": "1-1;x;o;1-2;o;x",
  "F#dim": "2-2;x;1-1;2-3;1-1;x;",
  "Gdim": "3-2;x;2-1;3-3;2-1;x;",
  "G#dim": "2-2;x;1-1;2-3;1-1;x;#3",
  "Abdim": "2-2;x;1-1;2-3;1-1;x;#3",
}

function makeFirstLine(data){
  var fret, finger
  return data.map(d => {
    [fret, finger] = d
    finger = String(finger)
    if(fret === 0)
      return finger
    return unsafeHTML('&nbsp;')
  })
}
function makeLines(data, span){
  var fret, finger, range
  range = [...Array(span)]
  return range.map((n, currentFret) => {
    finger = String(finger)
    return data.map(d => {
      [fret, finger] = d
      if(fret === currentFret+1){
        if(!/^(x|o)$/.test(finger))
          return finger
      }
      return '|'
    })
  })
}

function fixFinger(finger, dots){
  if(dots)
    return '\u25CF'
  if(!finger)
    return '\u25CF'
  if(/^(x|X|o|O|0)$/.test(finger))
    return finger.toLowerCase().replace('0', 'o')
  if(finger == '*')
    return '\u25CF'
  if(/^\d+$/.test(finger))
    return finger
  return '\u25CF'
}
function fixFret(fret){
  if(!fret)
    return 0
  if(/\d+/.test(fret))
    return parseInt(fret)
  return 0
}

function splitFormat(fmt, defaultName){
    const m = fmt.match(/(([^=]+)+=)?([^#]+)(#(\d+))?/)
    var name = defaultName,
      format = 'x;x;x;x;x;x',
      position = 0
    if(!m)
      return [name, format, position]
    if(m[2])
      name = m[2]
    if(m[5])
      position = parseInt(m[5])
    format = m[3]
    return [name, format, position]
}

function positionString(n){
  const num = String(n)
  if(/^1\d$/.test(num))
    return `${num}th`
  if(/\d*1$/.test(num))
    return `${num}st`
  if(/^\d*2$/.test(num))
    return `${num}nd`
  if(/^\d*3$/.test(num))
    return `${num}rd`
  return `${num}th`
}

async function getLib(nameOrUrl){
  var lib
  if(nameOrUrl == '' || nameOrUrl === null || !nameOrUrl)
    return INTERMEDIATE_CHORDS
  if(nameOrUrl == 'inbuilt')
    return INTERMEDIATE_CHORDS
  if(!storage.storageExists())
    return INTERMEDIATE_CHORDS
  if(/^(https?:\/\/|\/)/.test(nameOrUrl))
    lib = JSON.parse(storage.getItem(nameOrUrl))
    if(lib)
      return lib
    const api = new Api('/')
    try {
      lib = await api.get(nameOrUrl)
      storage.setItem(nameOrUrl, JSON.stringify(lib))
      return lib
    } catch (e) {
      console.log('failed to fetch', nameOrUrl, e);
      return INTERMEDIATE_CHORDS
    }
  return INTERMEDIATE_CHORDS
}

function cellClass(txt, num){
  if(num === 0)
    return 'ascii-head-cell'
  if(txt == '|')
    return 'ascii-string-cell'
  return 'ascii-finger-cell'
}

export default {
  tagname: 'ascii-chord',
  observedAttributes: ['format', 'minspan', 'maxspan', 'name', 'liburl', 'dots', 'fontsize'],
  init: function(e){
    this.element = e.currentTarget
    this.showchord = this.showchord.bind(this)
    this.onconnected = this.onconnected.bind(this)
    this.state = {}
    this.props = {}
  },
  showchord: async function(){
    propsMixin({
      minspan: 3,
      maxspan: 5,
      name: '?',
      lib: 'inbuilt'
    }, this)
    var {format: fmt, lib, name: nm, dots, fontsize=22} = this.props
    const library = await getLib(lib)
    fmt = fmt || library[nm] || 'x;x;x;x;x;x'
    const [name, format, position] = splitFormat(fmt, nm)
    const lines = this.buildLines({format, dots})
    this.setState({lines, format, position, name, fontsize})
  },
  onconnected: function(){
    this.showchord()
  },
  setState: function(obj){
    Object.assign(this.state, obj)
    this.render(this.props, this.state)
  },
  buildLines: function({format}){
    const {minspan, maxspan, name, dots} = this.props
    const data = format.split(';').map(p => {
      const sp = p.split('-')
      if(sp.length === 1){
        return [0, fixFinger(sp[0])]
      }
      return [fixFret(sp[0]), fixFinger(sp[1], dots)]
    })
    const stringnum = data.length
    const fretnums = data.map(d => parseInt(d[0])).sort()
    const minfret = fretnums[0]
    const maxfret = fretnums[fretnums.length -1]
    var lines, linenum, i, line
    var span = maxfret
    span = span < minspan? minspan: span
    lines = []
    linenum = span + 2
    lines.push(makeFirstLine(data))
    makeLines(data, span).forEach(l => lines.push(l));
    return lines
  },
  renderLine: function(line, num){
      return html`
        <div id="line-num-${num}" class="ascii-cc-line">
          ${line.map(u => html`<span class="ascii-cc-cell ${cellClass(u, num)}">${u}</span>`)}
        </div>
        <div class="ascii-cc-fret"></div>
    `
  },
  render: function(props, {lines, format, position, name, fontsize}){
    render(html`
<div class="ascii-cc" style="font-size: ${fontsize}px">
    ${position > 1?
      html`<div class="ascii-cc-position"><span>${positionString(position)}</span></div>`: ''
    }
    <div class="ascii-cc-title">${name || '?'}</div>
    <div class="ascii-cc-body">
      ${lines.map((l, i) => this.renderLine(l, i))}
    </div>
</div>
`, this.element)
},
  style: css`
.ascii-cc {
  display: inline-block;
  line-height: 1.3em;
  box-sizing: border-box;
}
.ascii-cc-body {
  font-family: "Lucida Console", Courier, monospace;
}
.ascii-cc-line {
  margin: 0px;
  padding: 0px;
  display: flex;
}
.ascii-string-cell {
  color: #9f9f9f;
}
.ascii-finger-cell {}
.ascii-cc-fret {
  width: calc(100% - 0.5em);
  margin-top: -1px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  border-bottom: 1px solid #9f9f9f;
}
.ascii-cc-position {
  width: 100%;
}
.ascii-cc-position span{
  font-size: 60%;
  position: absolute;
  margin-top: 4em;
  margin-left: 3.1em;
}

  `
}
