import Component from '../src/component'
import {render, html} from 'lit-html'
const css = String.raw

class StudentHome extends Component{
  static tagname = 'student-home'
  get observedattributes(){return ['slug', 'name', 'zoomlink']}
  state = {
    sections:[],
    order: 'desc',
    user: {}
  }

  async connect(store){
    this.toggleOrder = () => store.dispatch('toggleSectionOrder')
    var {routes, studentSlug, sectionOrder, user} = store.state
    this.setState({slug: studentSlug, user}, false)
    if(store.state.sections.length == 0) await store.dispatch('fetchSections')
    this.removeListener = store.on('set', (store, prop, newVal, oldVal) => {
      if(prop === 'sections') this.setState({sections: newVal, order: store.state.sectionOrder}, true)
      if(prop === 'user') this.setState({user: newVal}, true)
    })
    await store.dispatch('toggleSectionOrder', sectionOrder)
  }
  onDisConnected(){
    this.removeListener()
  }
  makeInfoPanel({user, zoomlink}){
    var {slot} = user
    slot = slot == ""? undefined: slot;
    return html`
    <div class="info-stack">
      <table>
        <tbody>
          <tr><th><i class="icon-calendar"></i>Lesson Time</th><td>${slot || 'not assigned'}</td></tr>
          <tr><th><i class="icon-videocam"></i>Zoom Link</th><td><a href="${zoomlink}" target="_blank" class="shake">launch zoom</a></td></tr>
        </tbody>
      </table>
    </div>
    `
  }
  render = ({slug, name, zoomlink}, {sections, order, user}) => {
    render(html`
<div class="page">
  <div class="student-home-container">
  <h1>${name}</h1>
    ${this.makeInfoPanel({user, zoomlink})}
    <div>
      <button class="student-rep-order-button button"
        @click=${this.toggleOrder}>${order == 'desc'? 'newest first': 'oldest first'}
      </button>
    </div>
    <ol class="list">
      ${sections.map(s => {
        return html`<li><a class="student-rep-list-link" href="${s.route}">${s.title}</a></li>`
      })}
    </ol>
  </div>
</div>
    `, this.root)
  }
  static style = css`
.info-stack{
  margin-bottom: 1em;
}
.info-stack td {
  padding-left: 10px;
  color: var(--color-pale-grey);
}
.info-stack th {
  text-align: left;
}
.info-stack a {
  color: var(--color-pale-grey);
  text-decoration: underline;
  display: inline-block;
}
.info-stack a:hover {
  color: var(--text-color);
}
.info-stack i {
  margin-right: 1em;
}
.student-home-container{
  width: 100%;
}
.student-home-container h1 {
  text-align: center;
}
.student-rep-order-button {
  width: 100%;
}
.student-rep-list-link{
  width: 100%;
  display: inline-block;
}
.student-rep-list-link:hover{
  background: var(--color-very-pale-grey);
}
@media (min-width: 700px) {
  .student-home-container{
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .student-rep-order-button {
    width: 100%;
  }
}
  `
}

export default StudentHome
