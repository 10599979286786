import {render, html} from 'lit-html'
import Component from '../src/component'
import {storage, getUrlParameter} from '../src/helpers'

class MainApp extends Component {
  static tagname = 'main-app'
  props = {url: window.location.pathname}
  get observedAttributes() { return ['url', 'menupath', 'routespath'] }
  connect = async (store) => {
    const {menupath, url, routespath} = this.props
    store.commit('setConfig', {
      menuUrl: menupath,
      routesUrl: routespath
    })
    store.commit('setAppRoot', this.root)
    const username = storage.getItem('username')
    const hash = storage.getItem('hash')
    const noindexlogin = getUrlParameter('noindexlogin')
    await store.dispatch('defaultLogin')
    if(username && hash && !noindexlogin){
      await store.dispatch('login', {username, hash}).catch(e => {
        console.log('failed to login, clearing saved credentials.');
        store.dispatch('logout')
      })
    }
    store.dispatch('navigate', url || window.location.href)
  }
}

export default MainApp
