import {hydrateAttrs} from '../src/helpers'
import store from '../src/state'
class Component {
  props = {}
  state = {}
  init(e){
    this.root = e.currentTarget
    this._id = Math.random().toString(36).substr(2, 9);
  }
  _updateProps = (props, render=true) => {
    Object.assign(this.props, props)
    if(this.render && render) this.render(this.props, this.state)
  }
  _loadProps = (render=false) => {
    this._updateProps(hydrateAttrs(this.root.attributes), render)
  }
  async onconnected(){
    //if(window._RENDERING != undefined) window._RENDERING.push(this._id)
    this._loadProps(!this.connect)
    if(this.onConnected) await this.onConnected(e)
    if(this.connect){
      const props = await this.connect(store) || {}
      this._updateProps(props, true)
    }
  }
  async ondisconnected(e){
    if(this.onDisconnected) this.onDisconnected(e)
    if(this.removeListener) this.removeListener()
  }
  async onattributechanged(e){
    this._loadProps(true)
    if(this.onAttributechanged) this.onAttributechanged(e)
  }
  setState = (obj, update=true) => {
    this.state = Object.assign({}, this.state, obj)
    if(this.render && update) this.render(this.props, this.state)
    //if(window._RENDERING != undefined) window._RENDERING.splice(window._RENDERING.indexOf(this._id), 1)
  }
}
export default Component
