import Component from '../src/component'
import {getUrlParameter, storage} from '../src/helpers'
import {render, html} from 'lit-html'
const css = String.raw

class LoginForm extends Component{
  static tagname = 'login-form'
  state = {
    errors: {},
    username: '',
    hash: ''
  }

  connect = async (store) => {
    //Login callback
    this.login = async (hash, username) => {
      await store.dispatch('login', {hash, username}).catch( e => {
          storage.removeItem('username')
          storage.removeItem('hash')
          store.commit('setUser', undefined)
          throw Error('Failed to login')
      })
    }

    //Submit form callback
    this.submitForm = async (e) => {
      e.preventDefault()
      const els = e.currentTarget.elements
      const username = els.namedItem('username').value
      const hash = els.namedItem('hash').value
      var errors = {}
      if(!username){
        errors['username'] = 'username required'
      }
      if(!hash){
        errors['hash'] = 'hash required'
      }
      if(Object.keys(errors).length != 0){
        this.setState({errors, username, hash})
      }
      else {
        await this.login(hash, username).catch( e => {
          errors.main = 'username or hash is incorrect.'
        }).then(() => {
          if(errors.main){
            this.setState({errors, username, hash})
          }
          else {
            this.setState({errors: {}})
            store.dispatch('navigate', `/students/${username}`)
          }
        })

      }
      return false
    }

    // Auto login
    var username =  getUrlParameter('username') || storage.getItem('username')
    var hash =  getUrlParameter('hash') || storage.getItem('hash')
    console.log(`hash: ${hash} username: ${username}`);
    if(hash && username) {
      const user = store.state.user? store.state.user: {username: '_UNDEFINED_'}
      if(username != user.username){
        await this.login(hash, username)
      }
      if(window.location.pathname === '/login'){
        store.dispatch('navigate', `/students/${username}`)
      }
    }
  }
  render = (props, {errors, username, hash}) => {
    render(html`
<div class="login-page">
  <h1>Login</h1>
  ${errors.main? html`<div class="form-error">**${errors.main}</div>`: null}
  <form @submit=${this.submitForm}>
    <div class="form-component">
      <label for="username">Username</label>
      ${errors.username? html`<div class="form-error">${errors.username}</div>`: null}
      <input type="text" name="username" value="${username}"/>
    </div>
    <div class="form-component">
      <label for="hash">Hash</label>
      ${errors.hash? html`<div class="form-error">${errors.hash}</div>`: null}
      <input type="text" name="hash" value="${hash}"/>
    </div>
    <button class="button">Submit</button>
  </form>
</div>
  `, this.root)
  }
  static style = css`
    .login-page {
      max-width: 500px;
      padding: 2em;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  `
}
export default LoginForm
