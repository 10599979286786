import wickedElements from 'wicked-elements'
import {defineComponents} from './src/helpers'
import * as components from './components'
window.wickedElements = wickedElements
defineComponents(components, wickedElements.define)
window._METROGNOMESTYLE = {
    borderColor: "black",
    borderWidth: "2px",
    textColor: "#bf8e58",
    needleColor: "black",
    buttonColor: "saddlebrown",
    backgroundColor: "#fff",
    fontSize: "110%",
    cornerSize: "10px",
    needleTune: '0.15',
    shadowColor: 'grey'
}
