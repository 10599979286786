import {render, html} from 'lit-html';
import {propsMixin} from '../src/helpers'

export default {
  tagname: 'ascii-chords',
  observedAttributes: ['chords', 'lib', 'dots', 'fontsize'],
  init: function(e){
    this.element = e.currentTarget
    this.showchords = this.showchords.bind(this)
    this.onconnected = this.onconnected.bind(this)
  },
  showchords: function(){
    propsMixin({chords: 'x;x;x;x;x;x', fontsize: '24', dots: '', lib: ''}, this)
    var {chords} = this.props
    this.props.chords = chords.split(',')
    this.render(this.props)
  },
  onconnected: function(){
    this.showchords()
  },
  onAttributeChanged: function(){
    this.showchords()
  },
  render: function({chords, lib, dots, fontsize}){
    render(html`
<div class="chord-block">
  ${chords.map(c => html`
    <div class="chord-block-unit">
      ${!c.includes(';')?
        html`<ascii-chord
              name="${c}"
              lib="${lib}"
              dots="${dots}"
              fontsize="${fontsize}">
          </ascii-chord>`:
        html`<ascii-chord
              format="${c}"
              lib="${lib}"
              dots="${dots}"
              fontsize="${fontsize}">
          </ascii-chord>`}
      </div>`)}
</div>
`, this.element)
},
style: `
  .chord-block{
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }
  .chord-block-unit {
    display: inline-block;
    margin: 10px;
  }
`
}
